import dashboardApi from 'apis/disApi/dashboardApi'
import tileApi from 'apis/disApi/tileApi'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { logErrorMessage, buildDatagridDateFilterModel } from 'utils/functions/helpers'

export function sortByPosition(list) {
    if (!Array.isArray(list) || list?.length === 0 || !list) return []

    let sortedList = [...list].sort((a, b) => +a.position - +b.position)

    return sortedList
}

export function transformStringToPositionArr(position) {
    // Loại bỏ các ký tự không phải số từ chuỗi
    var cleanStr = position.replace(/[^\d,-]/g, '')

    // Chuyển đổi chuỗi thành mảng
    var arr = cleanStr.split(',')

    // Chuyển đổi các phần tử trong mảng thành số nguyên
    var result = arr.map(function (item) {
        return parseInt(item.trim())
    })

    return result
}

// Function tìm chỗ trống trong layout để đặt box mới
export function getEmptyPosition(layoutList) {
    let maxX = 0
    let maxY = 0
    for (const item of layoutList) {
        const itemRightX = item.x + item.w
        const itemBottomY = item.y + item.h
        if (itemRightX > maxX) maxX = itemRightX
        if (itemBottomY > maxY) maxY = itemBottomY
    }

    return { x: maxX, y: maxY }
}

export function getTileListWithPosition(tileList) {
    if (tileList?.length === 0) return []
    return tileList.map((tile) => {
        const [x, y, w, h] = transformStringToPositionArr(tile.position)
        return {
            ...tile,
            i: tile.name,
            x,
            y,
            w,
            h,
        }
    })
}

export function generateRandomID() {
    return Math.random().toString(36).substring(2)
}

const PRESET_CONDITION_KEYS = ['Master_DateTime', 'Master_Text']
const DATETIME_COLUMNS_KEY = ['Master_DateTime_Start', 'Master_DateTime_End']

export function getAutoUpdateFilters(linkedFields, selectedFields, dashboardKeyList) {
    try {
        if (linkedFields?.length === 0 || selectedFields?.length === 0)
            return { filterConfigs: [], hasPrimaryKey: false, conditions: {} }

        const result = []
        let conditions = {}
        let hasPrimaryKey = false

        for (let fieldId in linkedFields) {
            const selectedDashboardKey = dashboardKeyList.find((item) => item.id === fieldId)
            const isHasPrimaryKey = Boolean(selectedDashboardKey)
            const keyToGetData = selectedDashboardKey?.mobileUnitKey || selectedDashboardKey?.key
            const isPresetKey = PRESET_CONDITION_KEYS.includes(keyToGetData)
            const filteredValue = selectedFields?.[selectedDashboardKey?.tileKey]?.[keyToGetData]

            if (filteredValue && isHasPrimaryKey) {
                hasPrimaryKey = true
                if (isPresetKey) {
                    const columnName = linkedFields[fieldId]
                    conditions[columnName] = {
                        ...filteredValue,
                        target: columnName,
                        keyData: keyToGetData,
                    }
                } else {
                    if (DATETIME_COLUMNS_KEY.includes(keyToGetData)) {
                        result.push({
                            field: linkedFields[fieldId],
                            operator:
                                keyToGetData === 'Master_DateTime_Start'
                                    ? 'onOrAfter'
                                    : 'onOrBefore',
                            value: moment.utc(filteredValue),
                            id: generateRandomID(),
                            keyData: keyToGetData,
                        })
                    } else {
                        result.push({
                            field: linkedFields[fieldId],
                            operator: 'contains',
                            value: filteredValue,
                            id: generateRandomID(),
                        })
                    }
                }
            }
        }
        return { filterConfigs: result, hasPrimaryKey, conditions: conditions }
    } catch (err) {
        return { filterConfigs: [], hasPrimaryKey: false, conditions: {} }
    }
}

export const processCreateTilesOfNewMenu = async ({ copiedMenu, dashboardResponse, token }) => {
    const copiedDashboard = await dashboardApi.get(copiedMenu?.dashboard?.key, token)

    if (copiedDashboard?.data?.tiles?.length > 0) {
        const copiedTiles = copiedDashboard?.data?.tiles.map((tile) => ({
            ...tile,
            // Update dashboard key of copied menu
            key: undefined,
            dashboardKey: dashboardResponse.data.key,
        }))

        copiedTiles.forEach((dataItem) => {
            tileApi.add({
                dashboardKey: dashboardResponse.data.key,
                data: dataItem,
                token,
            })
        })
    }
}

export const createItemsOfSubmenu = async (copiedMenu, token) => {
    try {
        if (!copiedMenu?.items) return []

        const itemsOfSubmenu = copiedMenu?.items?.map((item) => ({
            ...item,
            name: item.name,
            position: item.position,
            dashboard: item.dashboard,
        }))

        const result = []

        for (const dataItem of itemsOfSubmenu) {
            const newDashboard = {
                ...dataItem,
                name: dataItem.name,
                position: dataItem.position,
            }

            try {
                const dashboardResponse = await dashboardApi.add(newDashboard, token)

                if (dashboardResponse.data) {
                    const newSubMenu = {
                        ...newDashboard,
                        dashboard: { key: dashboardResponse.data.key },
                    }

                    await processCreateTilesOfNewMenu({
                        copiedMenu: dataItem,
                        dashboardResponse,
                        token,
                    })

                    result.push(newSubMenu)
                }
            } catch (error) {
                logErrorMessage(error)
            }
        }

        return result
    } catch {
        return []
    }
}
