import axios from 'axios'
import axiosRetry from 'axios-retry'
import moment from 'moment'
import { apiUrl } from '../../apis/apiUrl'
import {
	DISPATCH_ACTIVE_TYPE,
	FEED_VIEW_TYPE,
	GEOFENCES_VIEW_TYPE,
	MESSAGES_VIEW_TYPE,
	MOBILE_UNIT_VIEW_TYPE,
} from './datagrid/CreatViewComponents/ViewDialogUtils'
import { isEmpty } from 'lodash'

/* Retry request */
axiosRetry(axios, { retries: 3 })

/* Cancel request */
const CancelTokenLoadNextRecordsQuery = axios.CancelToken
let cancelLoadNextRecordsQuery

const url = apiUrl()
const apiHeader = (token) => {
	return {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}
}

export const getViewRecords = async (formKey, projectKey, viewSession, token, cancelToken) => {
	const viewRecordsUrl = viewSession
		? `${url}/records/view?@projectKey=${projectKey}&@formKey=${formKey}&@viewSession=${viewSession}`
		: `${url}/records/view?@projectKey=${projectKey}&@formKey=${formKey}`
	return await axios.get(viewRecordsUrl, {
		headers: apiHeader(token),
		cancelToken: cancelToken?.token,
	})
}

export const getDeviceViewRecords = async (
	formKey,
	projectKey,
	deviceNumber,
	viewSession,
	token,
	cancelToken
) => {
	const viewRecordsUrl = viewSession
		? `${url}/records/view?@projectKey=${projectKey}&@formKey=${formKey}&@deviceNumber=${deviceNumber}&@viewSession=${viewSession}`
		: `${url}/records/view?@projectKey=${projectKey}&@formKey=${formKey}&@deviceNumber=${deviceNumber}`
	return await axios.get(viewRecordsUrl, {
		headers: apiHeader(token),
		cancelToken: cancelToken?.token,
	})
}

export const getActivityDeviceView = async (deviceKey, token, cancelToken) => {
	const viewRecordsUrl = `${url}/records/view?@type=activity&@deviceKey=${deviceKey}`
	return await axios.get(viewRecordsUrl, {
		headers: apiHeader(token),
		cancelToken: cancelToken?.token,
	})
}

export const getActivityView = async (token, cancelToken) => {
	const viewRecordsUrl = `${url}/records/view?@type=activity`
	return await axios.get(viewRecordsUrl, {
		headers: apiHeader(token),
		cancelToken: cancelToken?.token,
	})
}

export const getFormSelectedFromActivityKey = async (activityKey, token) => {
	const viewRecordsUrl = `${url}/activity/${activityKey}`
	return await axios.get(viewRecordsUrl, {
		headers: apiHeader(token),
	})
}

export const getViewTabViewRecords = async ({
	viewKey,
	viewSession,
	token,
	shouldRemoveViewSession,
	viewType,
}) => {
	// if (viewType === 'DEVICE') {
	// 	return await axios.get(`${url}/devices/view`, {
	// 		headers: apiHeader(token),
	// 	})
	// }

	const viewSessionQuery = shouldRemoveViewSession ? '' : `?@viewSession=${viewSession}`
	const newUrl = `${url}/views/${viewKey}${viewSessionQuery}`

	return await axios.get(newUrl, {
		headers: apiHeader(token),
	})
}

export const getAllTeams = (token) => {
	return axios.get(`${url}/teams`, {
		headers: apiHeader(token),
	})
}

export const getAllReports = (token) => {
	return axios.get(`${url}/reports/my`, {
		headers: apiHeader(token),
	})
}

export const getAllProjects = (token, cancelToken) => {
	return axios.get(`${url}/projects`, {
		headers: apiHeader(token),
		cancelToken: cancelToken?.token,
	})
}

export const getAllOwners = (token) => {
	return axios.get(`${url}/owners`, {
		headers: apiHeader(token),
	})
}

export const getFormDevices = (teamKey, token) => {
	return axios.get(`${url}/teams/${teamKey}/devices`, {
		headers: apiHeader(token),
	})
}

export const getProjectForms = (projectKey, token) => {
	return axios.get(`${url}/projects/${projectKey}/forms?@abandoned=true`, {
		headers: apiHeader(token),
	})
}

export const getOwnerViews = (ownerKey, token) => {
	return axios.get(`${url}/owners/${ownerKey}/views`, {
		headers: apiHeader(token),
	})
}

export const getAllViews = (token, customerKey) => {
	if (isEmpty(customerKey)) return {}
	return axios.get(`${url}/owners/${customerKey}/views`, {
		headers: apiHeader(token),
	})
}

export const loadForm = async (formKey, token) => {
	return await axios.get(`${url}/forms/${formKey}`, {
		headers: apiHeader(token),
	})
}

export const loadAllColumns = async (formKey, token) => {
	return await axios.get(`${url}/forms/${formKey}/columns`, {
		headers: apiHeader(token),
	})
}

export const loadRepeatableColumns = async (formKey, token) => {
	return await axios.get(`${url}/forms/${formKey}/columns/all`, {
		headers: apiHeader(token),
	})
}

export const loadAllViewColumns = async (viewKey, token) => {
	return await axios.get(`${url}/views/${viewKey}/columns`, {
		headers: apiHeader(token),
	})
}

export const loadAllActivityColumns = async (token) => {
	return await axios.get(`${url}/activity/columns`, {
		headers: apiHeader(token),
	})
}

export const getReportList = async (reportKey, token) => {
	return await axios.get(`${url}/reports/${reportKey}/results`, {
		headers: apiHeader(token),
	})
}

export const createView = async (view, token) => {
	const config = {
		headers: apiHeader(token),
	}

	return await axios.post(`${url}/views`, view, config)
}

export const getReportPdfSrc = (reportKey, optionSelected, token) => {
	return `${url}/reports/${reportKey}/results/${optionSelected}/pdf?token=${token}`
}

export const getReportExcelSrc = (reportKey, optionSelected, token) => {
	return `${url}/reports/${reportKey}/results/${optionSelected}/xlsx?token=${token}`
}

export const loadRecordsQuery = async (viewKey, viewSession, filter, token, viewType) => {
	const config = {
		headers: apiHeader(token),
		cancelToken: new CancelTokenLoadNextRecordsQuery(function executor(c) {
			cancelLoadNextRecordsQuery = c
		}),
	}
	let bodyParameters = {
		filter: filter || {},
		viewKey: viewKey,
	}

	if (viewSession) {
		bodyParameters = { ...bodyParameters, viewSession }
	}
	if (viewType === MOBILE_UNIT_VIEW_TYPE) {
		bodyParameters.filter.conditions = []
		return await axios.post(`${url}/devices/query`, bodyParameters, config)
	} else if (viewType === FEED_VIEW_TYPE) {
		return await axios.post(`${url}/feed/query`, bodyParameters, config)
	} else if (viewType === DISPATCH_ACTIVE_TYPE) {
		return await axios.post(`${url}/dispatches/query`, bodyParameters, config)
	} else if (viewType === MESSAGES_VIEW_TYPE) {
		return await axios.post(`${url}/messages/query`, bodyParameters, config)
	} else if (viewType === GEOFENCES_VIEW_TYPE) {
		return await axios.post(`${url}/geofences/query`, bodyParameters, config)
	} else {
		return await axios.post(`${url}/records/query`, bodyParameters, config)
	}
}

export const loadDeviceRecordsQuery = async (
	viewKey,
	deviceNumber,
	viewSession,
	filter,
	token,
	isActivityViewDataType
) => {
	const config = {
		headers: apiHeader(token),
	}
	let deviceFilter = filter.conditions.filter((cond) => cond.target !== '@DeviceNumber')

	if (!isActivityViewDataType) {
		let deviceFilterUserStatus = deviceFilter.filter((cond) => cond.target === '@UserStatus')
		if (deviceFilterUserStatus === null || deviceFilterUserStatus.length === 0) {
			deviceFilter = [...deviceFilter, { target: '@UserStatus', type: 'EQ', preset: 'ALL' }]
		}
	}

	let bodyParameters = {
		deviceNumber: deviceNumber,
		filter: { conditions: deviceFilter },
		viewKey: viewKey,
	}

	if (viewSession) {
		bodyParameters = { ...bodyParameters, viewSession }
	}

	return await axios.post(`${url}/records/query`, bodyParameters, config)
}

export const loadNextRecordsQuery = async (view, viewSession, token) => {
	const config = {
		headers: apiHeader(token),
		cancelToken: new CancelTokenLoadNextRecordsQuery(function executor(c) {
			cancelLoadNextRecordsQuery = c
		}),
	}

	const beginDate = new moment()
	view.begin = beginDate
	let bodyParameters = {
		view: view,
	}

	if (viewSession) {
		bodyParameters = { ...bodyParameters, viewSession }
	}

	if (view?.type === MOBILE_UNIT_VIEW_TYPE) {
		return await axios.post(`${url}/devices/query`, bodyParameters, config)
	}
	if (view?.type === DISPATCH_ACTIVE_TYPE) {
		return await axios.post(`${url}/dispatches/query`, bodyParameters, config)
	} else {
		return await axios.post(`${url}/records/query`, bodyParameters, config)
	}
}

export const cancelLoadNextRecordsAction = () => {
	cancelLoadNextRecordsQuery?.()
}
//
// export const loadDeviceNextRecordsQuery = async (view, viewSession, token) => {
//     const config = {
//         headers: apiHeader(token)
//     };
//
//     const beginDate = new moment();
//     view.begin = beginDate;
//     const bodyParameters = {
//         view: view,
//     };
//
//     return await axios.post(`${url}/records/query`,
//         bodyParameters,
//         config
//     )
// }

export const updateColumns = async ({
	viewKey,
	columnsArr,
	viewSession,
	token,
	shouldRemoveViewSession,
}) => {
	const config = {
		headers: apiHeader(token),
	}

	const viewSessionQuery = shouldRemoveViewSession ? '' : `?@viewSession=${viewSession}`

	return await axios.put(`${url}/views/${viewKey}/columns${viewSessionQuery}`, columnsArr, config)
}

export const updateView = async (view, token) => {
	const config = {
		headers: apiHeader(token),
	}

	return await axios.put(`${url}/views/${view.key}`, view, config)
}

export const deleteView = (viewKey, token) => {
	const config = {
		headers: apiHeader(token),
	}

	return axios.delete(`${url}/views/${viewKey}`, config)
}

export const getProject = (projectKey, token) => {
	return axios.get(`${url}/projects/${projectKey}`, {
		headers: apiHeader(token),
	})
}

export async function getDispatchProjectInfo(dispatchKey, token) {
	const config = {
		headers: apiHeader(token),
	}

	return await axios.get(`${url}/dispatches/${dispatchKey}`, config)
}

export async function getActiveDispatchesInitialView(token, cancelToken) {
	const config = {
		headers: apiHeader(token),
		cancelToken: cancelToken?.token,
	}

	return axios.get(`${url}/dispatches/current/view`, config)
}

export async function getActiveDispatchesColumns(token, cancelToken) {
	const config = {
		headers: apiHeader(token),
		cancelToken: cancelToken?.token,
	}
	return axios.get(`${url}/dispatches/current/view/columns`, config)
}

export async function getActiveDispatchesAllRecords(token, cancelToken) {
	const config = {
		headers: apiHeader(token),
		cancelToken: cancelToken?.token,
	}
	return axios.post(`${url}/dispatches/query`, config)
}
