import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Button, MenuItem, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import enUS from 'date-fns/locale/en-US'
import _ from 'lodash'
import moment from 'moment'
import {
    datesValues,
    nonDatesValues,
    operatorOptions,
} from '../../../utils/functions/conditionsOptions'
import {
    computeDateBetween,
    computeDateValue,
    parseAs,
    calculateUserTimezoneOffset,
    fromUTCToTimezoneOffset,
    toDayjs,
} from '../../../utils/functions/doformsDateUtil'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import { FORM_ACTIONS } from '../../../reducers/formsReducer'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import { ACTIVITY_ACTIONS } from '../../../reducers/activityReducer'
import {
    computeDateOperatorValue,
    computeNonDateOperatorValue,
    defaultStatusName,
    ONLY_EQUALS_AND_OPERATOR_COLUMN,
} from '../dataHelpers'
import DoformsDataColumns from './DoformsDataColumns'
import { ACTIVITY_DEVICE_LIST, ACTIVITY_RECEIVE_TIME_KEY } from '../../../constants'
import {
    DISPATCH_ACTIVE_TYPE,
    FEED_VIEW_TYPE,
    MOBILE_UNIT_VIEW_TYPE,
    MESSAGES_VIEW_TYPE,
    GEOFENCES_VIEW_TYPE,
} from './CreatViewComponents/ViewDialogUtils'

const useStyles = makeStyles(() => ({
    root: {
        minWidth: '300px',
    },

    columnBox: {
        maxWidth: '15em',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '0',
            },
        },
    },

    filtersContainer: {
        margin: '16px',
    },

    footer: {
        // marginTop: theme.spacing(1),
        display: 'flex',
        minHeight: '52px',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .MuiButton-root': {
            textTransform: 'none !important',
        },
    },

    footerLeft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '8px',
    },

    footerRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    formRoot: {
        padding: '16px',
        justifyContent: 'space-around',
    },

    menuItem: {
        fontSize: '13px',
        paddingTop: 'none',
        paddingBottom: 'none',
    },

    subheader: {
        margin: 'none',
    },

    operatorBox: {
        minWidth: '120px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },
    },

    customValueBox: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0`,
            },
        },

        '& .MuiOutlinedInput-input': {
            minHeight: '1.65em',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: '400',
            fontSize: '0.875rem',
            lineHeight: '1.4375em',
            letterSpacing: '0.00938em',
            padding: '8.5px 14px !important',
        },

        '& .MuiTextField-root': {
            marginLeft: '0 !important',
        },
    },

    presetsValueBox: {
        width: 175,
        display: 'inline-grid',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `0 4px 4px 0`,
            },
        },
    },

    dateRangePicker: {
        display: 'inline-flex',
    },
}))

const DoformsConditionsBuilder = (props) => {
    const [t] = useTranslation('common')
    const classes = useStyles()
    const operators = operatorOptions(t)
    const nonDateOperators = operators.filter((op) => !['EMPTY', 'NOTEMPTY'].includes(op.type))
    const [locale, setLocale] = useState(enUS)

    const defaultTypeColumn = {
        date: {
            name: '@StartTime',
            title: t('common:filters.startDate'),
            type: 'DATETIME',
            format: { date: 'MM/DD/YYYY h:mm A' },
            width: 100,
        },
        activityDate: {
            name: ACTIVITY_RECEIVE_TIME_KEY,
            title: t('common:filters.dateReceived'),
            type: 'DATETIME',
            format: { date: 'MM/DD/YYYY h:mm A' },
            width: 100,
        },
        messagesDate: {
            name: '@MessageTime',
            title: 'Sent',
            type: 'DATETIME',
            format: { date: 'MM/DD/YYYY h:mm A' },
            width: 100,
        },
        nonDate: {
            name: '@UserStatus',
            title: t('common:filters.status'),
            type: 'STRING',
            format: {},
            width: 100,
        },
    }

    const {
        environment,
        query,
        viewData,
        columns,
        tab,
        conditions,
        iconsTheme,
        onBuilderClosed,
        keyDownEnter,
        setPrimaryFilter,
        isWizard,
    } = props

    const VALID_DATE_INPUT_LENGTH = 10
    const [builderConditions, setBuilderConditions] = useState(conditions)
    const [isOpenDate, setIsOpenDate] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [dateValue, setDateValue] = useState(conditions?.[0]?.values?.[0])
    // const [multiDateValue, setMultiDateValue] = React.useState([null, null])
    const dateValueRef = useRef(null)
    const inputRef = useRef()

    const { environment: reduxEnviroment } = useSelector((state) => state)
    const userOffset = useMemo(() => {
        return calculateUserTimezoneOffset(reduxEnviroment)
    }, [reduxEnviroment])

    useEffect(() => {
        if (_.isEqual(conditions, builderConditions)) return
        setBuilderConditions(conditions)
    }, [conditions])

    const checkHavePresetKey =
        builderConditions.length > 0 && builderConditions[0] && 'preset' in builderConditions[0]
    const isBetweenCondition =
        builderConditions.length > 0 &&
        builderConditions[0] &&
        builderConditions[0].type === 'BT' &&
        builderConditions[0].values.filter(Boolean).length === 2
    const equalCondition = _.isEqual(
        conditions,
        builderConditions.map((item, index) => {
            return index === 0 ? { ...item, values: [moment.utc(dateValue)] } : item
        })
    )
    const isDisabledBtn = checkHavePresetKey
        ? _.isEqual(conditions, builderConditions)
        : equalCondition || dateValue?.length < VALID_DATE_INPUT_LENGTH

    const dispatch = useDispatch()

    useEffect(() => {
        const importLocaleFile = async () => {
            const localeToSet = await import(
                `date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
            )
            setLocale(localeToSet.default)
        }

        if (locale.code !== t('common:languages.dateFnsLocale')) {
            importLocaleFile()
        }
    }, [t('common:languages.dateFnsLocale')])

    useEffect(() => {
        dateValueRef?.current?.focus()
        if (setPrimaryFilter && !_.isEqual(conditions, builderConditions)) {
            setPrimaryFilter([...builderConditions])
        }
    }, [builderConditions])

    useEffect(() => {
        if (isDisabledBtn && !isBetweenCondition) return
        if (
            keyDownEnter !== null &&
            (!_.isEqual(conditions, builderConditions) || dateValueRef?.current?.value)
        ) {
            onBuild()
        }
    }, [keyDownEnter])

    const callbackInputRef = (inputElement) => {
        if (inputElement) {
            setTimeout(() => {
                inputElement.focus()
            }, 150)
        }
    }

    function getSingleInputBuilderConditions(value) {
        let momentObj = moment(value)
        if (momentObj.isValid()) {
            return builderConditions.map((item, index) =>
                index === 0 ? { ...item, values: [momentObj] } : item
            )
        }
        return null
    }

    const onBuild = (e) => {
        e && e.stopPropagation()

        // because when there is 'preset' key then don't get 'value' key to call api
        const currentBuilderConditions = builderConditions
        switch (tab) {
            case 'lookups':
                dispatch({
                    type: LOOKUPS_ACTIONS.FORM_FILTER_CONDITIONS,
                    payload: currentBuilderConditions,
                })
                dispatch({
                    type: LOOKUPS_ACTIONS.DATA_GRID,
                    payload: {
                        query: {
                            ...query,
                            filter: {
                                conditions: currentBuilderConditions,
                            },
                        },
                    },
                })
                dispatch({
                    type: LOOKUPS_ACTIONS.LOOKUPS_REFRESH,
                    payload: true,
                })
                break
            case 'views':
                dispatch({
                    type: VIEWS_ACTIONS.FORM_FILTER_CONDITIONS,
                    payload: currentBuilderConditions,
                })
                dispatch({
                    type: VIEWS_ACTIONS.DATA_GRID,
                    payload: {
                        query: {
                            ...query,
                            filter: {
                                conditions: currentBuilderConditions,
                            },
                        },
                    },
                })
                dispatch({
                    type: VIEWS_ACTIONS.VIEW_REFRESH,
                    payload: true,
                })
                break
            case 'devices':
                dispatch({
                    type: DEVICES_ACTIONS.FORM_FILTER_CONDITIONS,
                    payload: currentBuilderConditions,
                })
                dispatch({
                    type: DEVICES_ACTIONS.DATA_GRID,
                    payload: {
                        query: {
                            ...query,
                            filter: {
                                conditions: currentBuilderConditions,
                            },
                        },
                    },
                })
                dispatch({
                    type: DEVICES_ACTIONS.DEVICE_REFRESH,
                    payload: true,
                })
                break
            case 'activity':
                dispatch({
                    type: ACTIVITY_ACTIONS.FORM_FILTER_CONDITIONS,
                    payload: currentBuilderConditions,
                })
                dispatch({
                    type: ACTIVITY_ACTIONS.DATA_GRID,
                    payload: {
                        query: {
                            ...query,
                            filter: {
                                conditions: currentBuilderConditions,
                            },
                        },
                    },
                })
                dispatch({
                    type: ACTIVITY_ACTIONS.ACTIVITY_REFRESH,
                    payload: true,
                })
                break
            case 'tile':
                break
            default: //FORMS
                dispatch({
                    type: FORM_ACTIONS.FORM_FILTER_CONDITIONS,
                    payload: currentBuilderConditions,
                })
                dispatch({
                    type: FORM_ACTIONS.DATA_GRID,
                    payload: {
                        query: {
                            ...query,
                            filter: {
                                conditions: currentBuilderConditions,
                            },
                        },
                    },
                })
                dispatch({
                    type: FORM_ACTIONS.FORM_REFRESH,
                    payload: true,
                })
                break
        }
        onClosed()
    }

    const onClosed = () => {
        onBuilderClosed()
    }

    const dateColumns = useMemo(() => {
        let filteredColumns = columns?.filter((item) => ['DATETIME', 'DATE'].includes(item.type))
        const key = ACTIVITY_DEVICE_LIST.includes(viewData?.type)
            ? 'activityDate'
            : viewData?.type === MESSAGES_VIEW_TYPE
            ? 'messagesDate'
            : 'date'
        let hasDefaultColumn =
            filteredColumns?.findIndex((col) => col.name === defaultTypeColumn[key].name) >= 0
                ? true
                : false
        if (!hasDefaultColumn) {
            const date = defaultTypeColumn[key]
            filteredColumns = filteredColumns ? [...filteredColumns, date] : [date]
        }
        return filteredColumns
    }, [columns])

    const nonDateColumns = useMemo(() => {
        let filteredColumns = columns?.filter((item) => !['DATETIME', 'DATE'].includes(item.type))
        let hasDefaultColumn =
            //filteredColumns?.findIndex((col) => col.name === defaultTypeColumn.nonDate.name) >= 0
            filteredColumns?.findIndex((col) => defaultStatusName.includes(col.name)) >= 0
                ? true
                : false
        if (!hasDefaultColumn) {
            filteredColumns = filteredColumns
                ? [...filteredColumns, defaultTypeColumn.nonDate]
                : [defaultTypeColumn.nonDate]
        }
        return filteredColumns
    }, [columns])

    const handleDateColumnChange = (event) => {
        setBuilderConditions(
            builderConditions.map((item, index) =>
                index === 0 ? { ...item, target: event.target.value } : item
            )
        )
    }

    const handleNonDateColumnChange = (event) => {
        //	if (!defaultStatusName(event.target.value)) {
        if (!defaultStatusName.includes(event.target.value)) {
            setBuilderConditions(
                builderConditions.map((item, index) =>
                    index === 1 ? { ...item, target: event.target.value, type: 'EQ' } : item
                )
            )
        } else {
            setBuilderConditions(
                builderConditions.map((item, index) =>
                    index === 1 ? { ...item, target: event.target.value } : item
                )
            )
        }
    }

    const handleDateOperatorChange = (event) => {
        const value = event.target.value
        const isOperatorType = ['EQ', 'NE', 'BT', 'CT', 'EMPTY', 'NOTEMPTY'].includes(value)
        if (isOperatorType) {
            const now = moment()
            const today = now.clone().startOf('day')
            const todayISOString = parseAs(today, userOffset).toISOString()
            switch (value) {
                case 'EMPTY':
                    setBuilderConditions(
                        builderConditions.map((item, index) =>
                            index === 0
                                ? {
                                      target: item.target,
                                      preset: value,
                                      type: 'EQ',
                                      values: [todayISOString],
                                  }
                                : item
                        )
                    )
                    break
                case 'NOTEMPTY':
                    setBuilderConditions(
                        builderConditions.map((item, index) =>
                            index === 0
                                ? {
                                      target: item.target,
                                      preset: value,
                                      type: 'NE',
                                      values: [todayISOString],
                                  }
                                : item
                        )
                    )
                    break
                case 'BT':
                    setBuilderConditions(
                        builderConditions.map((item, index) => {
                            if (index !== 0) return item
                            let startMomentObj = moment(item.values?.[0]).startOf('day')
                            let endMomentObj = moment(item.values?.[1]).endOf('day').add(1, 's')

                            const fromUserMomentISOString = startMomentObj.isValid()
                                ? parseAs(startMomentObj, userOffset)?.toISOString()
                                : null
                            const endUserMomentISOString = endMomentObj.isValid()
                                ? parseAs(endMomentObj, userOffset)?.toISOString()
                                : null
                            return {
                                target: item.target,
                                type: 'BT',
                                join: 'AND',
                                values: [
                                    fromUserMomentISOString || '',
                                    endUserMomentISOString || '',
                                ],
                            }
                        })
                    )
                    break
                default:
                    setBuilderConditions(
                        builderConditions.map((item, index) => {
                            if (index !== 0) return item
                            let momentObj = moment(item.values?.[0]).startOf('day')
                            const fromUserMomentISOString = momentObj.isValid()
                                ? parseAs(momentObj, userOffset)?.toISOString()
                                : null
                            return {
                                target: item.target,
                                type: value,
                                values: item.values ? [fromUserMomentISOString] : [''],
                            }
                        })
                    )
                    break
            }
        } else {
            setBuilderConditions(
                builderConditions.map((item, index) =>
                    index === 0 ? { ...item, type: 'EQ', preset: value, values: [] } : item
                )
            )
        }
    }

    const handleNonDateOperatorChange = (event) => {
        const value = event.target.value
        const isOperatorType = ['EQ', 'NE', 'BT', 'CT'].includes(value)

        if (isOperatorType) {
            switch (value) {
                case 'BT':
                    setBuilderConditions(
                        builderConditions.map((item, index) =>
                            index === 1
                                ? {
                                      target: item.target,
                                      type: value,
                                      join: 'AND',
                                      values: item.values,
                                  }
                                : item
                        )
                    )
                    break
                default:
                    setBuilderConditions(
                        builderConditions.map((item, index) =>
                            index === 1
                                ? {
                                      target: item.target,
                                      type: value,
                                      values: item.values ? [item.values[0]] : [''],
                                  }
                                : item
                        )
                    )
                    break
            }
        } else {
            setBuilderConditions(
                builderConditions.map((item, index) =>
                    index === 1 ? { target: item.target, type: 'EQ', preset: value } : item
                )
            )
        }
    }

    const nonDateInputChanged = (event) => {
        if (builderConditions[1]?.type === 'BT') {
            setBuilderConditions(
                builderConditions.map((item, index) =>
                    index === 1
                        ? {
                              target: item.target,
                              type: item.type,
                              join: 'AND',
                              values: [event.target.value],
                          }
                        : item
                )
            )
        } else {
            setBuilderConditions(
                builderConditions.map((item, index) =>
                    index === 1
                        ? { target: item.target, type: item.type, values: [event.target.value] }
                        : item
                )
            )
        }
    }

    const nonDateBetweenInputChanged = (event) => {
        const eventId = event.target.id
        if (eventId === 'nonDateBetweenInput1') {
            setBuilderConditions(
                builderConditions.map((item, index) =>
                    index === 1
                        ? {
                              target: item.target,
                              type: 'BT',
                              values: [event.target.value, item.values[1] ? item.values[1] : ''],
                          }
                        : item
                )
            )
        } else {
            setBuilderConditions(
                builderConditions.map((item, index) =>
                    index === 1
                        ? {
                              target: item.target,
                              type: 'BT',
                              values: [item.values[0] ? item.values[0] : '', event.target.value],
                          }
                        : item
                )
            )
        }
    }

    const handleChangeSingleDatePicker = (newValue, isOnInput) => {
        if (!newValue?.isValid()) {
            return
        }
        const fromDateString = newValue.format('YYYY-MM-DD HH:mm:ss')
        const startDayMoment = moment(fromDateString).clone().startOf('day')
        const userMoment = parseAs(startDayMoment, userOffset)
        function handleDate(value) {
            if (!value.isValid()) return

            const userMomentISOString = value.toISOString()
            setBuilderConditions(
                builderConditions.map((item, index) =>
                    index === 0 ? { ...item, values: [userMomentISOString] } : item
                )
            )
        }

        const momentValue = userMoment.format('YYYY-MM-DD')
        if (isOnInput) {
            setDateValue(momentValue)
            handleDate(userMoment)
        } else {
            if (!isOpenDate) return
            handleDate(userMoment)
            setDateValue(momentValue)
        }
    }

    const handleChangeDateRangePicker = (values) => {
        let startMomentObj = moment(values[0]?.format('YYYY-MM-DD HH:mm:ss')).startOf('day')
        let endMomentObj = moment(values[1]?.format('YYYY-MM-DD HH:mm:ss')).endOf('day').add(1, 's')
        if (!startMomentObj.isValid() || !endMomentObj.isValid()) return
        const fromUserMoment = parseAs(startMomentObj, userOffset)
        // WFM-267: Add 1 second to correct rs from server
        const endUserMoment = parseAs(endMomentObj, userOffset)
        if (fromUserMoment.isValid() && endUserMoment.isValid()) {
            const startDateISOString = fromUserMoment.toISOString()
            const endDateISOString = endUserMoment.toISOString()
            setBuilderConditions((prev) =>
                prev.map((item, index) =>
                    index === 0 ? { ...item, values: [startDateISOString, endDateISOString] } : item
                )
            )
        }
        // setMultiDateValue([fromUserMoment.format('YYYY-MM-DDTHH:mm:ss'), endUserMoment.format('YYYY-MM-DDTHH:mm:ss')])
    }

    const showColumnsBox = (type) => {
        let listDateColumn = dateColumns
        if (viewData?.type === MOBILE_UNIT_VIEW_TYPE || viewData?.type === FEED_VIEW_TYPE || viewData?.type === GEOFENCES_VIEW_TYPE) {
            listDateColumn = dateColumns?.filter((x) => x.name !== '@StartTime')
        }

        let columnsSet = type === 'date' ? listDateColumn : nonDateColumns
        let columnValue =
            type === 'date' ? builderConditions[0]?.target : builderConditions[1]?.target

        // if(columnValue === "@StartTime" || columnValue === "@UserStatus") {
        //     const found = columnsSet.indexOf(col => col.name === columnValue);
        //     if(found < 0) {
        //         columnsSet = [...columnsSet, type === "date"? defaultTypeColumn.date: defaultTypeColumn.nonDate]
        //     }
        // }
        return (
            <TextField
                id="columnSelection"
                className={classes.columnBox}
                size="small"
                color="primary"
                variant="outlined"
                select={true}
                label={t('common:filters.field')}
                value={columnValue}
                onChange={type === 'date' ? handleDateColumnChange : handleNonDateColumnChange}
            >
                {columnsSet.map((option) => (
                    <MenuItem key={option.name} value={option.name} className={classes.menuItem}>
                        {option.title}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    const showOperatorsBox = (type) => {
        let presets = type === 'date' ? datesValues(t) : nonDatesValues(t)
        //name:'ALL'
        const operatorValue =
            type === 'date'
                ? computeDateOperatorValue(builderConditions[0] || {})
                : computeNonDateOperatorValue(builderConditions[1] || {})

        if (
            type === 'date' &&
            builderConditions?.length >= 2 &&
            builderConditions[0]?.target === '@FeedReceiveTime'
        ) {
            presets = presets.filter((item) => item.name !== 'All')
        }

        if (
            type === 'nonDate' &&
            builderConditions?.length >= 2 &&
            ONLY_EQUALS_AND_OPERATOR_COLUMN.includes(builderConditions[1]?.target)
        ) {
            presets = presets.filter((item) => item.name === 'EQ' || item.name === 'All')
        }
        let operatorSet = type === 'date' ? operators : nonDateOperators

        if (
            type === 'nonDate' &&
            builderConditions?.length >= 2 &&
            ONLY_EQUALS_AND_OPERATOR_COLUMN.includes(builderConditions[1]?.target)
        ) {
            operatorSet = operatorSet.filter((item) => item.name === 'Equals')
        }
        const showPresetOptions =
            type === 'date'
                ? true
                : defaultStatusName.includes(builderConditions[1].target)
                ? true
                : false
        return (
            <TextField
                id="operatorsSelection"
                className={classes.operatorBox}
                size="small"
                select={true}
                label={t('common:filters.operator')}
                color="primary"
                variant="outlined"
                value={operatorValue}
                onChange={type === 'date' ? handleDateOperatorChange : handleNonDateOperatorChange}
            >
                {operatorSet.map((option) => (
                    <MenuItem key={option.name} value={option.type} className={classes.menuItem}>
                        {option.name}
                    </MenuItem>
                ))}
                {presets.length && showPresetOptions && <hr className={classes.subheader} />}
                {presets.length &&
                    showPresetOptions &&
                    presets.map((option) => (
                        <MenuItem
                            key={option.preset}
                            value={option.preset}
                            className={classes.menuItem}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
            </TextField>
        )
    }

    const showDateValueBox = () => {
        const operator = builderConditions[0]?.type
        const presetSelected = builderConditions[0]?.preset ? builderConditions[0]?.preset : null

        if (presetSelected) {
            switch (presetSelected) {
                case 'EMPTY':
                case 'NOTEMPTY':
                case 'ALL':
                    return null
                case 'TODAY':
                case 'YESTERDAY':
                case 'TOMORROW':
                    return renderSingleDatePicker()
                default:
                    return renderDateRangePicker()
            }
        } else {
            switch (operator) {
                case 'EQ':
                case 'NE':
                case 'CT':
                    return renderSingleDatePicker()
                case 'BT':
                    return renderDateRangePicker()
                default:
                    return null
            }
        }
    }

    const showNonDateValueBox = () => {
        const target = builderConditions[1]?.target
        const operator = builderConditions[1]?.type
        const presetSelected = builderConditions[1]?.preset ? builderConditions[1]?.preset : null

        if (defaultStatusName.includes(target)) {
            if (presetSelected) {
                return null
            }
        }

        switch (operator) {
            case 'BT':
                return renderNonDateBetweenFields()
            default:
                return renderNonDateField()
        }
    }

    const renderSingleDatePicker = () => {
        const preset = builderConditions[0]?.preset ? builderConditions[0]?.preset : null
        let dateValues
        if (preset) {
            const valuesByPreset = computeDateValue(preset)
            dateValues = valuesByPreset.map((item) => {
                if (!item) return ''
                const userMoment = parseAs(item, userOffset)
                return userMoment?.toISOString()
            })
        } else {
            dateValues = builderConditions[0]?.values
        }
        const dateValue = dateValues.length ? dateValues[0] : null
        const stringValue = fromUTCToTimezoneOffset(dateValue, userOffset, 'YYYY-MM-DDTHH:mm:ss')
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
                <DatePicker
                    label={t('common:filters.date')}
                    value={toDayjs(stringValue)}
                    disabled={preset ? true : false}
                    onChange={(newValue) => handleChangeSingleDatePicker(newValue, false)}
                    onOpen={() => setIsOpenDate(true)}
                    onClose={() => setIsOpenDate(false)}
                    className={classes.customValueBox}
                    slotProps={{
                        textField: {
                            onChange: (e) => {
                                handleChangeSingleDatePicker(e, true)
                            },
                            ref: inputRef,
                            size: 'small',
                            variant: 'outlined',
                            label: t('common:filters.value'),
                        },
                    }}
                />
            </LocalizationProvider>
        )
    }
    const renderDateRangePicker = () => {
        const preset = builderConditions[0]?.preset ? builderConditions[0]?.preset : null
        //TODO (remove offset): const dateRangeValues = preset? computeDateValue(preset): computeDateBetween(builderConditions[0].values, environment.userCurrent.time.timezone.isDst?environment.userCurrent.time.timezone.offset - 60:environment.userCurrent.time.timezone.offset)
        let dateRangeValues
        if (preset) {
            const valuesByPreset = computeDateValue(preset)
            dateRangeValues = valuesByPreset.map((item) => {
                if (!item) return ''
                const userMoment = parseAs(item, userOffset)
                return userMoment?.toISOString()
            })
        } else if (builderConditions?.[0]?.type === 'BT') {
            const displayValues = builderConditions?.[0]?.values
            const startValue = displayValues?.[0]
            const endValue = displayValues?.[1]

            // WFM-267: add 1 second to correct search rs from api
            // subtract 1s for display value
            const endMoment = moment.utc(endValue).subtract(1, 's')
            dateRangeValues = [startValue, endMoment.toISOString()]
        } else {
            dateRangeValues = [
                builderConditions?.[0]?.values?.[0],
                builderConditions?.[0]?.values?.[1],
            ]
        }
        const momentDateRangeValues = dateRangeValues?.map((utcString) => {
            return toDayjs(fromUTCToTimezoneOffset(utcString, userOffset, 'YYYY-MM-DDTHH:mm:ss'))
        })
        return (
            <div id="dateRangePicker" className={classes.dateRangePicker}>
                <LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
                    <MobileDateRangePicker
                        defaultValue={momentDateRangeValues}
                        disabled={preset ? true : false}
                        onChange={(newValue) => handleChangeDateRangePicker(newValue)}
                        className={classes.customValueBox}
                        slotProps={{
                            fieldSeparator: {
                                display: 'none',
                            },
                        }}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <TextField
                                    {...startProps}
                                    label={t('common:filters.from')}
                                    defaultValue={toDayjs(momentDateRangeValues[0]) || null}
                                    size="small"
                                    variant="outlined"
                                />
                                <TextField
                                    {...endProps}
                                    label={t('common:filters.to')}
                                    defaultValue={toDayjs(momentDateRangeValues[1]) || null}
                                    size="small"
                                    variant="outlined"
                                />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </div>
        )
    }

    const renderNonDateField = () => {
        const dataValues = builderConditions[1]?.values

        return (
            <TextField
                id="nonDateInput"
                inputRef={callbackInputRef}
                className={classes.customValueBox}
                value={dataValues && dataValues[0] ? dataValues[0] : ''}
                onChange={nonDateInputChanged}
                label={t('common:filters.value')}
                size="small"
                variant="outlined"
            />
        )
    }

    const renderNonDateBetweenFields = () => {
        const dataValues = builderConditions[1]?.values

        return (
            <Fragment>
                <TextField
                    id="nonDateBetweenInput1"
                    inputRef={callbackInputRef}
                    className={classes.customValueBox}
                    value={dataValues && dataValues[0] ? dataValues[0] : ''}
                    onChange={nonDateBetweenInputChanged}
                    label={t('common:filters.from')}
                    size="small"
                    variant="outlined"
                />
                <TextField
                    id="nonDateBetweenInput2"
                    className={classes.customValueBox}
                    value={dataValues && dataValues[1] ? dataValues[1] : ''}
                    onChange={nonDateBetweenInputChanged}
                    label={t('common:filters.to')}
                    size="small"
                    variant="outlined"
                />
            </Fragment>
        )
    }

    const renderFields = (type) => {
        const id = type === 'date' ? 'dateFields' : 'nonDateFields'
        return (
            <form id={id} className={classes.formRoot}>
                {showColumnsBox(type)}
                {showOperatorsBox(type)}
                {type === 'date' ? showDateValueBox() : showNonDateValueBox()}
            </form>
        )
    }

    return (
        <div id="condition-builder" className={classes.root}>
            {dateColumns.length &&
                !_.isEmpty(builderConditions) &&
                ![DISPATCH_ACTIVE_TYPE].includes(viewData?.type) &&
                renderFields('date')}
            {![ACTIVITY_DEVICE_LIST, GEOFENCES_VIEW_TYPE].includes(viewData?.type) &&
                nonDateColumns.length &&
                !_.isEmpty(builderConditions) &&
                renderFields('nonDate')}
            {/* {tab === 'forms' && <div>{t('common:misc.messageQueryData')}</div>} */}

            <Typography
                variant="subtitle1"
                style={{
                    color: 'gray',
                    fontSize: 14,
                }}
            >
                {t('common:misc.messageQueryData')}
            </Typography>

            {builderConditions && !isWizard && (
                <div className={classes.footer}>
                    <div className={classes.footerLeft}>
                        {/* <DoformsDataColumns
							from={'condition-builder'}
							environment={environment}
							viewData={viewData}
							columns={columns}
							tab={tab}
							recordsLoading={false}
						/> */}
                    </div>
                    <div className={classes.footerRight}>
                        <Button sx={iconsTheme} onClick={onClosed}>
                            {t('common:misc.cancel')}
                        </Button>
                        <Button
                            sx={iconsTheme}
                            disabled={isDisabledBtn && !isBetweenCondition}
                            onClick={onBuild}
                        >
                            {t('common:misc.runQuery')}
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default DoformsConditionsBuilder
